export default function selectChange() {
	const propertyTypeSelector = document.querySelector('#select-property_type')
	const submitBtn = document.querySelector(
		'.beautiful-taxonomy-filters-button'
	)

	if (propertyTypeSelector) {
		propertyTypeSelector.addEventListener('change', () => {
			submitBtn.click()
		})
	}
}
