import { accordion } from './components/accordion'
import {
	readMoreSlides,
	swiperDesktop,
	swiperMobile,
} from './components/swiper'
import { hamburger, mobileHeader, stickyHeader } from './components/header'
import { viewAnimation, smoothScroll } from './util/animation'
import { outputMonth, countDown } from './util/dates'
import { inlineVideo } from './util/video'
import { phoneConcat, readMore } from './util/text'
import { cf7Redirect } from './util/window'
import collapsible from './components/collapsible'
import modal from './components/modal'
import selectChange from './util/selectChange'
import './components/google-maps'
import readMoreContent from './util/readMore'
import rolodex from './components/rolodex'
import wistiaFilterRemove from './components/wistia-filter-remove'
;(function ($) {
	$(window).on('load', function () {
		collapsible()
		modal()
		mobileHeader()
		stickyHeader()
		hamburger()
		outputMonth()
		viewAnimation()
		rolodex()
		wistiaFilterRemove()
		accordion()
		swiperDesktop()
		swiperMobile()
		readMoreSlides()
		smoothScroll()
		inlineVideo()
		phoneConcat()
		selectChange()
		readMoreContent()
	})
})(jQuery)
