import { Modal } from 'bootstrap'

export default function modal() {
	let modalLinks = [
		{
			selector: '[data-bs-target*="#popup-property-guide"]',
			modal: 'popup-property-guide',
		},
		{
			selector: '[data-bs-target*="#buyers-popup"]',
			modal: 'buyers-popup',
		},
		{
			selector: '[data-bs-target*="#vendors-popup"]',
			modal: 'vendors-popup',
		},
		{
			selector: '[data-bs-target*="#free-consultation-popup"]',
			modal: 'free-consultation-popup',
		},
		{
			selector: '[data-bs-target*="#vendors-page-popup"]',
			modal: 'vendors-page-popup',
		},
		{
			selector: '[data-bs-target*="#buyers-page-popup"]',
			modal: 'buyers-page-popup',
		},
	]

	modalLinks.forEach((link) => {
		let links = document.querySelectorAll(link.selector)

		if (links.length) {
			for (var i = 0; i < links.length; i++) {
				links[i].addEventListener('click', (evt) => {
					evt.preventDefault()
					let target = document.querySelector('#' + link.modal)
					new Modal(target).show()
				})
			}
		}
	})

	let modals = document.querySelectorAll('.modal')

	if (modals.length) {
		for (var i = 0; i < modals.length; i++) {
			new Modal(modals[i])
		}
	}
}
