import Swiper, {
	Pagination,
	Navigation,
	Autoplay,
	Virtual,
	EffectFade,
} from 'swiper'

// Modifies the options to include those set within the data-* attributes.
function getSwiperOptionAttributes(options, swiperElm) {
	// These options can be set via the attributes of the swiper element
	const setable = {
		loop: 'loop',
		spaceBetween: 'spacebetween',
		slidesPerView: 'slidesperview',
		autoplay: 'autoplay',
	}

	var optionsAttributes = swiperElm.dataset

	for (var key in setable) {
		var value = setable[key]
		if (optionsAttributes[value]) {
			options[key] = isNaN(optionsAttributes[value])
				? optionsAttributes[key]
				: parseInt(optionsAttributes[value])
		}

		// Breakpoint attributes if any
		if (optionsAttributes['768_' + value]) {
			options.breakpoints[768][key] = isNaN(
				optionsAttributes['768_' + value]
			)
				? optionsAttributes['768_' + value]
				: parseInt(optionsAttributes['768_' + value])
		}
		if (optionsAttributes['996_' + value]) {
			options.breakpoints[996][key] = isNaN(
				optionsAttributes['996_' + value]
			)
				? optionsAttributes['996_' + value]
				: parseInt(optionsAttributes['996_' + value])
		}
		if (optionsAttributes['1340_' + value]) {
			options.breakpoints[1340][key] = isNaN(
				optionsAttributes['1340_' + value]
			)
				? optionsAttributes['1340_' + value]
				: parseInt(optionsAttributes['1340_' + value])
		} else if (optionsAttributes['996_' + value]) {
			options.breakpoints[1340][key] = isNaN(
				optionsAttributes['996_' + value]
			)
				? optionsAttributes['996_' + value]
				: parseInt(optionsAttributes['996_' + value])
		}
	}

	return options
}

export function swiperDesktop() {
	// get all sliders, we need to loop them due to different settings + nav
	var swipers = document.querySelectorAll(
		'.swiper-container:not(.control):not(.mobile)'
	)
	swipers.forEach(async function (el, index) {
		var closestSection = el.closest('section')
		var controls = closestSection.querySelector('.control')

		// Slider default settings
		var options = {
			speed: 600,
			modules: [Pagination, Navigation, Autoplay, Virtual, EffectFade],
			loop: true,
			slidesPerView: 1,
			watchSlidesProgress: true,
			// autoplay: {
			// 	delay: 5000,
			// 	disableOnInteraction: true,
			// },
			breakpoints: {
				768: {},
				996: {},
				1340: {},
			},
			navigation: {},
			thumbs: {},
		}

		// Slider attribute overrides
		options = getSwiperOptionAttributes(options, el)

		// init nav
		options.navigation = {
			nextEl: closestSection.querySelector('.swiper-next'),
			prevEl: closestSection.querySelector('.swiper-prev'),
		}

		// maybe add gallery controls to the slider
		if (controls) {
			var controlOptions = {
				speed: 600,
				loop: true,
				slidesPerView: 4,
			}

			controlOptions = getSwiperOptionAttributes(controlOptions, controls)

			options.thumbs.swiper = new Swiper(controls, controlOptions)
		}

		// Property Gallery Section Swiper
		if (el.classList.contains('property-gallery')) {
			options.lazy = { loadPrevNext: true }
			options.spaceBetween = 20
			options.breakpoints = {
				768: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				996: {
					slidesPerView: 3,
					spaceBetween: 10,
				},
			}
		}

		// Single Property Page Swiper
		if (el.classList.contains('single-property-swiper')) {
			options.spaceBetween = 25
		}

		// Advocates Section Swiper
		if (el.classList.contains('advocates-swiper')) {
			;(options.lazy = {
				loadPrevNext: true,
			}),
				(options.spaceBetween = 10),
				(options.breakpoints = {
					768: {
						slidesPerView: 3,
					},
					996: {
						slidesPerView: 4,
						spaceBetween: 20,
					},
				})
		}

		// Testimonials Section Swiper
		if (el.classList.contains('testimonials-swiper')) {
			;(options.spaceBetween = 20),
				(options.pagination = {
					el: closestSection.querySelector('.swiper-pagination'),
					clickable: true,
				}),
				(options.breakpoints = {
					768: {
						slidesPerView: 1,
					},
					996: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
				})
		}

		// Recent News Section Swiper
		if (el.classList.contains('recent-news-swiper')) {
			;(options.spaceBetween = 20),
				(options.breakpoints = {
					768: {
						slidesPerView: 2,
					},
					996: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
				})
		}

		// Video Testimonials Swiper
		if (el.classList.contains('video-testimonials-swiper')) {
			;(options.spaceBetween = 20),
				(options.pagination = {
					el: closestSection.querySelector('.swiper-pagination'),
					clickable: true,
				}),
				(options.breakpoints = {
					768: {
						slidesPerView: 2,
					},
					996: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
				})
		}

		if (el.classList.contains('hero-swiper')) {
			options.autoplay = {
				delay: 5000,
			}
			options.speed = 5000
			options.effect = 'fade'
			options.crossFade = true
		}

		// init slider
		new Swiper(el, options)
	})
}

export function swiperMobile() {
	// mobile sliders, like logo rows etc
	// need to loop in order to get the slide count
	var mobileSwipers = document.querySelectorAll('.swiper-container.mobile')
	mobileSwipers.forEach(function (el, index) {
		var closestSection = el.closest('section')
		var slideCount = el.querySelectorAll('.swiper-slide').length

		var options = {
			speed: 600,
			slidesPerView: 2,
			watchOverflow: true,
			loop: true,
			simulateTouch: false,
			observer: true,
			observeParents: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			breakpoints: {
				576: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 4,
				},
				992: {
					slidesPerView: slideCount,
				},
			},
		}

		// init nav
		options.navigation = {
			nextEl: closestSection.querySelector('.swiper-next'),
			prevEl: closestSection.querySelector('.swiper-prev'),
		}

		options = getSwiperOptionAttributes(options, el)

		// init slider
		swiper = new Swiper(el, options)
	})
}

export function readMoreSlides() {
	document
		.querySelectorAll(
			'.testimonials-swiper .section-testimonials__card-content'
		)

		.forEach((el) => {
			if (el.clientHeight > 450) {
				let readMoreCard = el.closest('.section-testimonials__card')

				readMoreCard.classList.add('readmore-card')

				el.insertAdjacentHTML(
					'beforeend',
					'<button class="readMore-btn btn btn-primary">Read More</button>'
				)
			}
		})

	document.querySelectorAll('.readMore-btn').forEach((el) => {
		let slide = el.closest('.swiper-container')

		el.addEventListener('click', (e) => {
			slide.classList.toggle('readmore-open')
			e.target.innerText === 'Read More'
				? (e.target.innerText = 'Read Less')
				: (e.target.innerText = 'Read More')
		})
	})
}
