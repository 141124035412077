export default function wistiaFilterRemove() {
	const wistiaVid = document.querySelector(
		'.section-hero-video [data-wistia-id]'
	)
	const darkFilterStyle = document.querySelector('.darken-video')

	if (wistiaVid) {
		window._wq = window._wq || []

		const wistiaID = wistiaVid.getAttribute('data-wistia-id')

		_wq.push({
			id: wistiaID,
			onReady: function (video) {
				video.bind('play', function () {
					darkFilterStyle.classList.remove('darken-video')
				})
			},
		})
	}
}
